//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import Reviews from "@/components/Reviews";
import { review_service } from "@/services";
export default {
  name: "reviews-page",
  components: {
    BaseLayout,
    Reviews,
  },
  metaInfo: {
    title: "Reviews",
  },
  data() {
    return {
      reviews: [
        {
          id: 1,
          content: "",
          liked_by: [],
          published_at: "",
          rating: 5.0,
          movie: {
            id: 1,
            title: "",
            poster: "",
            about: "",
          },
        },
      ],
    };
  },
  mounted() {
    this.reviews.splice(0, 1);
    this.get_reviews();
  },
  computed: {
    profile_id() {
      return this.$route.params.id;
    },
  },
  methods: {
    get_reviews() {
      review_service
        .get({ author__id: this.profile_id })
        .then((res) => {
          this.reviews = res.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
